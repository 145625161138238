import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import CameraIcon from '@material-ui/icons/PhotoCamera';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Container from '@material-ui/core/Container';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import { LayoutCustom } from '../layouts/custom';
import Link from '@material-ui/core/Link';
import React from 'react';
import SEO from '../components/seo';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { graphql } from 'gatsby';
import { makeStyles } from '@material-ui/core/styles';
import { navigate } from 'gatsby';
import styles from './teams.module.scss';
import teamImage from '../assets/img/team.png';

const useStyles = makeStyles(theme => ({
  icon: {
    marginRight: theme.spacing(2)
  },
  heroContent: {
    padding: theme.spacing(2, 0, 0)
  },
  cardGrid: {
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(8)
  },
  card: {
    backgroundColor: 'var(--blue)',
    height: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  cardMedia: {
    paddingTop: '56.25%' // 16:9
  },
  cardContent: {
    flexGrow: 1
  },
  title: {
    color: 'white'
  },
  intro: {
    color: 'white'
  },
  more: {
    color: 'var(--teal-light)'
  }
}));

const TeamsPage = ({ data }) => {
  const classes = useStyles();
  const teams = data.allMarkdownRemark.edges
    .filter(item => {
      if (!item.node.fields || !item.node.fields.slug) {
        return false;
      }

      return item.node.fields.slug.includes('/teams/');
    })
    .map(team => ({
      ...team.node.frontmatter,
      ...{ link: team.node.fields.slug }
    }))
    .sort((a, b) => {
      if (a.sorting > b.sorting) {
        return 1;
      }

      if (a.sorting < b.sorting) {
        return -1;
      }

      return 0;
    });

  return (
    <LayoutCustom>
      <SEO title="Teams" />
      <div className={classes.heroContent}>
        <h2 className={`text-center ${styles.title}`}>Abbots Youth FC Teams</h2>
      </div>
      <div className={`row ${styles.teams}`}>
        <Container className={classes.cardGrid} maxWidth="md">
          <Grid container spacing={4}>
            {teams.map((team, index) => {
              return (
                <Grid item key={index} xs={12} sm={6} md={4}>
                  <Card className={classes.card}>
                    <CardMedia
                      className={classes.cardMedia}
                      image={team.image.publicURL}
                      title={team.name}
                    />
                    <CardContent className={classes.cardContent}>
                      <Typography
                        className={classes.title}
                        gutterBottom
                        variant="h5"
                        component="h2"
                      >
                        {team.title}
                      </Typography>
                      <Typography className={classes.intro}>
                        {team.intro}
                      </Typography>
                    </CardContent>

                    <CardActions>
                      <Button
                        size="small"
                        className={classes.more}
                        onClick={event => {
                          event.preventDefault();
                          navigate(team.link);
                        }}
                      >
                        MORE
                      </Button>
                    </CardActions>
                  </Card>
                </Grid>
              );
            })}
          </Grid>
        </Container>
      </div>
    </LayoutCustom>
  );
};

export default TeamsPage;

export const query = graphql`
  query TeamsQuery {
    allMarkdownRemark {
      edges {
        node {
          id
          fields {
            slug
          }
          frontmatter {
            title
            intro
            image {
              publicURL
            }
            manager
            sorting
          }
        }
      }
    }
  }
`;

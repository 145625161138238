import ImageCharterStandard from '../components/images/charter-standard';
import { LayoutHome } from '../layouts/home';
import React from 'react';
import SEO from '../components/seo';
import classnames from 'classnames';
import standardImage from '../assets/img/charter-standard.png';
import styles from './index.module.scss';

const IndexPage = () => (
  <LayoutHome>
    <SEO title="Home" />
    <div className={`${styles.intro} w-100 h-100`}>
      <h1>ABBOTS YOUTH</h1>
      <h2>FOOTBALL CLUB</h2>
      <span className={styles.divider}></span>
      <strong>Coach me, I will learn.</strong>
      <strong>Challenge me, I will improve.</strong>
      <strong>Believe in me, I will grow.</strong>
    </div>
    <div className={styles.crest}>
      <a
        href="https://www.englandfootball.com/run/leagues-and-clubs/england-football-accreditation"
        target="_blank"
      >
        <img
          className={styles.standardImage}
          src={standardImage}
          alt="Accredited image"
        />
      </a>
    </div>
    <div className={styles.adverts}>
      <div className={classnames([styles.weebly, styles.totalfootballdirect])}>
        <a
          href="https://www.totalfootballdirect.com/club-shops/football-teams/abbots-youth-fc"
          target="_blank"
        >
          <img src="/cms/kit.jpg" alt="AYFC at totalfootballdirect.com" />
          <p className={styles.weeblyLabel}>
            AYFC
            <br />
            Kit Room
          </p>
        </a>
      </div>
      <div className={styles.weebly}>
        <a href="https://ayfcbootrm.weebly.com/" target="_blank">
          <img src="/cms/weebly.jpg" alt="AYFC at weebly.com" />
          <p className={styles.weeblyLabel}>
            AYFC
            <br />
            Boot Room
          </p>
        </a>
      </div>
    </div>
  </LayoutHome>
);

export default IndexPage;

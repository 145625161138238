import * as React from 'react';

import { LayoutDefault } from '../layouts/default';
import { Link } from 'gatsby';

export default () => {
  return (
    <LayoutDefault>
      <div className="row pb-5">
        <div className="col-md-4"></div>
        <div className="col-md-8">
          <h1>Message sent!</h1>
          <p>You have successfully send you message.</p>
          <Link to="/">Go to home page</Link>
        </div>
      </div>
    </LayoutDefault>
  );
};

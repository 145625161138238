import PropTypes from "prop-types";
import React from "react";

export const StripesRightMedium = props => {
  const { type } = props;

  return (
    <span className={`stripes stripes-right stripes-medium`}>
      <em className="stripe stripe-bg"></em>
      <em className="stripe stripe-first"></em>
      <em className="stripe stripe-second"></em>
      <em className="stripe stripe-third"></em>
      <em className="stripe stripe-fourth"></em>
      <em className="stripe stripe-fifth"></em>
      <em className="stripe stripe-sixth"></em>
    </span>
  );
};

import React from 'react';
import { getRandomInt } from '../../helpers';
import heroImageAlbion from '../../assets/img/player-hero-albion.png';
import heroImageBen from '../../assets/img/player-hero-ben.png';
import heroImageChris from '../../assets/img/player-hero-chris.png';
import heroImageDan from '../../assets/img/player-hero-dan.png';
import heroImageEthan from '../../assets/img/player-hero-ethan.png';
import heroImageMiko from '../../assets/img/player-hero-miko.png';
import heroImageOwen from '../../assets/img/player-hero-owen.png';
import heroImageRob2 from '../../assets/img/player-hero-rob2.png';
import heroImageSpencer from '../../assets/img/player-hero-spencer.png';
import heroImageTed from '../../assets/img/player-hero-ted.png';
import heroImageTed2 from '../../assets/img/player-hero-ted2.png';
import styles from './aside.module.scss';

const heroImages = [
  heroImageAlbion,
  heroImageBen,
  heroImageChris,
  heroImageDan,
  heroImageEthan,
  heroImageMiko,
  heroImageOwen,
  heroImageRob2,
  heroImageSpencer,
  heroImageTed,
  heroImageTed2
];

export const Aside = props => {
  const { index } = props;
  const position = index
    ? parseInt(index, 10)
    : getRandomInt(0, heroImages.length - 1);
  const heroImage = heroImages[position];

  return (
    <aside className="col-md-4">
      <div className={styles.playerBackground}>
        <img src={heroImage} alt="AYFC Player background" />
      </div>
      <div className="p-4">
        <div className={styles.player}>
          <img src={heroImage} alt="AYFC Player" />
        </div>
      </div>
    </aside>
  );
};

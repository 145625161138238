module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-web-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"custom":{"families":["Nunito","Dosis"]}},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Abbots Youth FC","short_name":"Abbots Youth FC","start_url":"/","background_color":"#153787","theme_color":"#15647C","display":"standalone","icon":"src/assets/img/ayfc-crest-square.png","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"bf6a7267bfa66bf62f9b3ba05950f8a2"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]

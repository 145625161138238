import React, { useState } from 'react';

import { Aside } from '../components/aside';
import Axios from 'axios';
import { LayoutDefault } from '../layouts/default';
import SEO from '../components/seo';
import axios from 'axios';
import clubMapImage from '../assets/img/club-map.png';
import styles from './contact.module.scss';

function isValidEmail(email) {
  return /\S+@\S+\.\S+/.test(email);
}

function checkIsValid(event, setIsMessageSent, setIsMessageError) {
  event.preventDefault();

  const name = document.getElementById('contactform-sendername').value;
  const email = document.getElementById('contactform-email').value;
  const phone = document.getElementById('contactform-phone').value;
  const subject = document.getElementById('contactform-subject').value;
  const message = document.getElementById('contactform-message').value;
  const botField = document.getElementById('bot-field').value;
  let isValid = true;
  let errorMessage = '';

  if (botField) {
    errorMessage = 'It looks like you are a robot!';
    isValid = false;
    return;
  }

  if (!name) {
    errorMessage = 'Please enter your name!';
    isValid = false;
    return;
  }

  if (!email) {
    errorMessage = 'Please enter your e-mail!';
    isValid = false;
  }

  if (!isValidEmail(email)) {
    errorMessage = 'Please enter a correct e-mail address!';
    isValid = false;
  }

  if (!phone) {
    errorMessage = 'Please enter your phone number!';
    isValid = false;
  }

  if (!subject) {
    errorMessage = 'Please provide a subject for the message!';
    isValid = false;
  }

  if (!message) {
    errorMessage = 'Please enter your message!';
    isValid = false;
  }

  if (message && message.length < 10) {
    errorMessage = 'Your message is too short!';
    isValid = false;
  }

  if (!isValid) {
    window.alert(errorMessage);
  } else {
    axios
      .post(
        'https://pt1a8uiq0d.execute-api.eu-west-1.amazonaws.com/preprod/email',
        {
          from: {
            text: `${name} - ${email} - ${phone}`
          },
          subject,
          text: message
        }
      )
      .then(result => {
        setIsMessageSent(true);
        document.getElementById('contactform-sendername').value = '';
        document.getElementById('contactform-email').value = '';
        document.getElementById('contactform-phone').value = '';
        document.getElementById('contactform-subject').value = '';
        document.getElementById('contactform-message').value = '';
        document.getElementById('bot-field').value = '';
      })
      .catch(error => {
        console.warn('A server error occured.', error);
        setIsMessageError(true);
      });
  }
}

const ContactPage = () => {
  const [isMessageSent, setIsMessageSent] = useState(false);
  const [isMessageError, setIsMessageError] = useState(false);

  return (
    <LayoutDefault>
      <SEO title="Contact" />
      <div className="row">
        <Aside />
        <section className="col-md-8">
          <h2 className="text-center my-4">Contact us</h2>

          <p className="text-center w-responsive mx-auto mb-5">
            Do you have any questions? Please do not hesitate to contact us
          </p>

          <div className="row">
            <div className="col-md-8 pb-5">
              <form name="ayfc-contact">
                <p className="hidden">
                  <label htmlFor="bot-field">
                    Don't fill this out if you're human:
                    <input name="bot-field" id="bot-field" type="text" />
                  </label>
                </p>
                <div className="row">
                  <div className="col-md-12">
                    <div className="md-form mb-0">
                      <input
                        type="text"
                        name="name"
                        id="contactform-sendername"
                        className="form-control"
                        maxLength="24"
                        required
                      />
                      <label htmlFor="name" className="">
                        Your name
                      </label>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="md-form mb-0">
                      <input
                        type="text"
                        name="email"
                        id="contactform-email"
                        maxLength="50"
                        className="form-control"
                        required
                      />
                      <label htmlFor="email" className="">
                        Your email
                      </label>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="md-form mb-0">
                      <input
                        type="text"
                        name="phone"
                        id="contactform-phone"
                        maxLength="10"
                        className="form-control"
                        required
                      />
                      <label htmlFor="phone" className="">
                        Your phone number
                      </label>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <div className="md-form mb-0">
                      <input
                        type="text"
                        name="subject"
                        id="contactform-subject"
                        maxLength="24"
                        className="form-control"
                        required
                      />
                      <label htmlFor="subject" className="">
                        Subject
                      </label>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <div className="md-form">
                      <textarea
                        type="text"
                        name="message"
                        id="contactform-message"
                        maxLength="200"
                        rows="7"
                        className="form-control md-textarea"
                        required
                      ></textarea>
                      <label htmlFor="message">Your message</label>
                    </div>
                  </div>
                </div>
                <div className="text-center text-md-left">
                  {!isMessageSent && !isMessageError && (
                    <button
                      className="btn btn-primary"
                      onClick={event =>
                        checkIsValid(event, setIsMessageSent, setIsMessageError)
                      }
                    >
                      Send
                    </button>
                  )}
                  {isMessageSent && (
                    <div
                      className={`text-center ${styles.message} ${styles.messageConfirmation}`}
                    >
                      Your message has been sent, thank you!
                    </div>
                  )}
                  {isMessageError && (
                    <div
                      className={`text-center ${styles.message} ${styles.messageError}`}
                    >
                      Unfortunately, something went wrong. Your message has not
                      been sent. Please try again later.
                    </div>
                  )}
                </div>
              </form>

              <div className="status"></div>
            </div>
            <div className="col-md-4 text-left">
              <h5>Contact Postal address:</h5>
              <p>
                Abbots Youth Football Club
                <br />
                Manor House Sports
                <br />
                Social Centre, Gallows Hill Lane
                <br />
                Abbots Langley Hertfordshire WD5 0BX
              </p>
              <div className={styles.map}>
                <a
                  href="https://www.google.com/maps/place/Manor+House+Sports+Pavilion,+Abbots+Langley+WD5+0BU,+Wielka+Brytania/@51.7033278,-0.4237042,17z/data=!4m5!3m4!1s0x48764035f97c4df5:0xf3a4423c8cfe64ca!8m2!3d51.7034674!4d-0.4215726"
                  target="_blank"
                >
                  <img src={clubMapImage} alt="AYFC hq map" />
                </a>
              </div>
            </div>
          </div>
        </section>
      </div>
    </LayoutDefault>
  );
};

export default ContactPage;

// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-teams-tsx": () => import("./../../../src/pages/teams.tsx" /* webpackChunkName: "component---src-pages-teams-tsx" */),
  "component---src-templates-contact-success-tsx": () => import("./../../../src/templates/contact-success.tsx" /* webpackChunkName: "component---src-templates-contact-success-tsx" */),
  "component---src-templates-static-page-tsx": () => import("./../../../src/templates/static-page.tsx" /* webpackChunkName: "component---src-templates-static-page-tsx" */),
  "component---src-templates-team-page-tsx": () => import("./../../../src/templates/team-page.tsx" /* webpackChunkName: "component---src-templates-team-page-tsx" */)
}


import { Footer } from '../components/footer';
import { Header } from '../components/header';
import PropTypes from 'prop-types';
import React from 'react';
import { StripesLeftDark } from '../components/stripes/left-dark';
import { StripesRightMedium } from '../components/stripes/right-medium';
import heroImage from '../assets/img/player-hero-rob.png';
import styles from './layout.module.css';

export const LayoutHome = ({ children }) => {
  return (
    <div className={`${styles.layout} ${styles.home} p-4`}>
      <div className={styles.playerBackground}>
        <img src={heroImage} alt="Player hero background" />
      </div>
      <StripesLeftDark />
      <StripesRightMedium />
      <div className={styles.playerHero}>
        <img src={heroImage} alt="Player hero" />
      </div>
      <Header />
      <main>{children}</main>
      <Footer />
    </div>
  );
};

LayoutHome.propTypes = {
  children: PropTypes.node.isRequired
};

import { Footer } from '../components/footer';
import { Header } from '../components/header';
import PropTypes from 'prop-types';
import React from 'react';
import { StripesRightMedium } from '../components/stripes/right-medium';
import styles from './layout.module.css';

export const LayoutCustom = ({ children }) => {
  return (
    <div className={`${styles.layout} ${styles.layoutCustom} p-4`}>
      <StripesRightMedium />
      <Header />
      <main>{children}</main>
      <Footer />
    </div>
  );
};

LayoutCustom.propTypes = {
  children: PropTypes.node.isRequired
};

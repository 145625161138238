import PropTypes from 'prop-types';
import React from 'react';

export const StripesLeftLight = props => {
  const { type } = props;

  return (
    <span className={`stripes stripes-left stripes-light`}>
      <em className="stripe stripe-first"></em>
      <em className="stripe stripe-second"></em>
      <em className="stripe stripe-third"></em>
      <em className="stripe stripe-fourth"></em>
      <em className="stripe stripe-fifth"></em>
    </span>
  );
};

export default StripesLeftLight;

import { Link } from 'gatsby';
import React from 'react';
import styles from './footer.module.css';

export const Footer = () => {
  const currentYear = new Date().getFullYear();

  return (
    <footer className={`mt-2 p-2 w100 text-right text-muted ${styles.footer}`}>
      <span>copyrights Abbots Youth FC &copy; 2002 - {currentYear}</span>
      <Link to="/privacy-policy">Privacy Policy</Link>
    </footer>
  );
};

import { Aside } from '../components/aside';
import { LayoutCustom } from '../layouts/custom';
import React from 'react';
import classnames from 'classnames';
import { getRandomInt } from '../helpers';
import { graphql } from 'gatsby';
import styles from './team-page.module.scss';

function goBack(event) {
  event.preventDefault();
  window.history.back();
}

export default ({ data }) => {
  const post = data.markdownRemark;

  const { title, subtitle, intro, image, manager } = post.frontmatter;

  return (
    <LayoutCustom>
      <div className="row pb-5">
        <div className="col-md-2"></div>
        <div className="col-md-8 team-page">
          <a className={styles.goBack} href="#" onClick={goBack}>
            go back
          </a>
          <div
            className={`jumbotron p-4 p-md-5 text-white rounded ${styles.jumbotron}`}
          >
            <div className={styles.intro}>
              <div className={styles.introContent}>
                {title && <h1 className="display-4 font-italic">{title}</h1>}
                <p className="lead my-3">Manager: {manager}</p>
              </div>
              {image && (
                <div className={styles.teamImage}>
                  <img src={image.publicURL} alt="team image" />
                </div>
              )}
            </div>
          </div>
          {subtitle && (
            <h3 className="pb-4 mb-4 font-italic border-bottom">{subtitle}</h3>
          )}
          <div className="blog-post team-page pl-4 pr-4">
            <div
              className={styles.content}
              dangerouslySetInnerHTML={{ __html: post.html }}
            />
          </div>
        </div>
      </div>
    </LayoutCustom>
  );
};

export const query = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        image {
          publicURL
        }
        keywords
        intro
        manager
      }
    }
  }
`;
